
import {Component, Vue} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import Pagination from '@/components/Pagination/index.vue';
import {printTemplates} from '../../../../resources';
import {ObjectId} from 'bson';
import {PrintTemplate} from '@/externals/MaxCI-PrintTemplate-v1';
@Component({
  name: 'printTemplateList',
  components: {
    multiSelect,
    Pagination,
  },
})
export default class extends Vue {
  private dialogDel = false; //删除弹窗
  private operateId: ObjectId | null = null;
  private total = 0;
  private list: Array<PrintTemplate> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private searchData = {name: ''};
  async created() {
    this.updateList();
  }
  //删除页面提示
  private delPopUps(id: ObjectId) {
    this.operateId = id;
    this.dialogDel = true;
  }
  //删除保存
  private async delSumbit() {
    try {
      if (this.operateId) {
        const shop = await printTemplates.delete(filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.operateId as ObjectId),
          ),
        );
        if (shop) {
          this.dialogDel = false;
          this.operateId = null;
          this.$message.success('已删除');
          this.updateList();
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //查询列表
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //查看详情
  private toDetail(id: ObjectId) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'edit',
        id: id.toHexString(),
      },
    });
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await printTemplates.find(stage =>
        stage.$facet(facet =>
          facet('table', tableStage =>
            tableStage
              .$skip((this.listParams.page - 1) * this.listParams.limit)
              .$limit(this.listParams.limit),
          )('count', countStage => countStage.$count('count')),
        ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  //新增
  private addBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'add'},
    });
  }
}
