
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {wechatTemplateDrafts, wechatTemplates} from '../../../../../resources';
import moment from 'moment';
import {WechatTemplateDraft} from '@/externals/MaxCI-WechatTemplateDraft-v1';
import {ObjectId} from 'bson';

@Component({
  name: 'schoolList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private formData = {
    channelType: '微信小程序',
  };
  private dialogTemplate = false; //授权弹窗
  private operateData: WechatTemplateDraft | null = null;
  private total = 0;
  private list: Array<WechatTemplateDraft> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private submitFlag = false; //提交开关

  async created() {
    this.updateList();
  }
  //授权弹窗
  private templatePopUps(row: WechatTemplateDraft) {
    this.operateData = row;
    this.dialogTemplate = true;
  }
  //更新授权保存
  private templateSumbit() {
    try {
      if (this.operateData?.spec) {
        wechatTemplates
          .create([
            {
              spec: {
                ...this.operateData?.spec,
                applicationId: ObjectId.createFromHexString(
                  this.$route.params.applicationId,
                ),
              },
            },
          ])
          .then(() => {
            this.$message.success('转化成功');
            this.dialogTemplate = false;
            this.updateList();
          });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //获取表格
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await wechatTemplateDrafts.find(stage => stage);
      this.list = list;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
}
