
import {Component, Vue} from 'vue-property-decorator';
import {configs} from '../../../../resources';
import {
  WechatComponentSettingSpec,
  YlyConfigSpec,
  ApplicationDefaultQuotaSpec,
  WechatComponentTokenSpec,
} from '@/externals/MaxCI-Config-v1';
import {Form} from 'element-ui';

@Component({
  name: 'systemConfig',
})
export default class extends Vue {
  private activeNames = ['1'];
  private applicationDefaultQuotaForm: null | ApplicationDefaultQuotaSpec =
    null; //应用默认配额规格
  private applicationDefaultQuotaRules = {
    applicationCount: [
      {required: true, message: '请输入每用户拥有应用数目', trigger: 'blur'},
    ],
    channelCount: [
      {required: true, message: '请输入渠道数目', trigger: 'blur'},
    ],
    mallShopCount: [
      {required: true, message: '请输入商城店铺数量', trigger: 'blur'},
    ],
  };
  private ylyConfigForm: null | YlyConfigSpec = null; //易联云设置
  private ylyConfigRules = {
    clientId: [{required: true, message: '请输入应用ID', trigger: 'blur'}],
    clientSecret: [
      {required: true, message: '请输入应用密钥', trigger: 'blur'},
    ],
    accessToken: [{required: true, message: '请输入访问令牌', trigger: 'blur'}],
  };
  private wechatComponentSettingForm: null | WechatComponentSettingSpec = null; //微信第三方平台设置
  private wechatComponentSettingRules = {
    supportVersion: [
      {required: true, message: '请输入最低基础库版本', trigger: 'blur'},
    ],
  };

  async created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    this.checkDetail();
  }
  //查询详情
  private async checkDetail() {
    const config = await configs.find(stage => stage);
    config.forEach(item => {
      if (item.spec.type === '微信第三方平台设置') {
        this.wechatComponentSettingForm = item.spec;
      } else if (item.spec.type === '易联云设置') {
        this.ylyConfigForm = item.spec;
      } else if (item.spec.type === '应用默认配额') {
        this.applicationDefaultQuotaForm = item.spec;
      }
    });
  }
  //应用默认配额
  private submitApplicationDefaultQuota() {
    (this.$refs.applicationDefaultQuotaForm as Form).validate(valid => {
      if (valid) {
        if (this.applicationDefaultQuotaForm) {
          const applicationDefaultQuotaForm = {
            type: this.applicationDefaultQuotaForm.type,
            applicationCount: Number(
              this.applicationDefaultQuotaForm.applicationCount,
            ),
            availableServices:
              this.applicationDefaultQuotaForm.availableServices,
            availableChannels:
              this.applicationDefaultQuotaForm.availableChannels,
            channelCount: Number(this.applicationDefaultQuotaForm.channelCount),
            mallShopCount: Number(
              this.applicationDefaultQuotaForm.mallShopCount,
            ),
          };
          this.submitForm('应用默认配额', applicationDefaultQuotaForm);
        }
      } else {
        return false;
      }
    });
  }
  //易联云设置保存
  private submitYlyConfig() {
    (this.$refs.ylyConfigForm as Form).validate(valid => {
      if (valid) {
        if (this.ylyConfigForm) {
          this.submitForm('易联云设置', this.ylyConfigForm);
        }
      } else {
        return false;
      }
    });
  }
  //微信第三方平台设置保存
  private submitWechatComponentSetting() {
    (this.$refs.wechatComponentSettingForm as Form).validate(valid => {
      if (valid) {
        if (this.wechatComponentSettingForm) {
          const wechatComponentSettingForm = {
            type: this.wechatComponentSettingForm.type,
            supportVersion: this.wechatComponentSettingForm.supportVersion,
            ...(this.wechatComponentSettingForm.webviewDomain &&
            this.wechatComponentSettingForm.webviewDomain.filter(v => v !== '')
              .length > 0
              ? {
                  webviewDomain:
                    this.wechatComponentSettingForm.webviewDomain.filter(
                      v => v !== '',
                    ),
                }
              : {}),
            ...(this.wechatComponentSettingForm.requestDomain &&
            this.wechatComponentSettingForm.requestDomain.filter(v => v !== '')
              .length > 0
              ? {
                  requestDomain:
                    this.wechatComponentSettingForm.requestDomain.filter(
                      v => v !== '',
                    ),
                }
              : {}),
            ...(this.wechatComponentSettingForm.wsRequestDomain &&
            this.wechatComponentSettingForm.wsRequestDomain.filter(
              v => v !== '',
            ).length > 0
              ? {
                  wsRequestDomain:
                    this.wechatComponentSettingForm.wsRequestDomain.filter(
                      v => v !== '',
                    ),
                }
              : {}),
            ...(this.wechatComponentSettingForm.uploadDomain &&
            this.wechatComponentSettingForm.uploadDomain.filter(v => v !== '')
              .length > 0
              ? {
                  uploadDomain:
                    this.wechatComponentSettingForm.uploadDomain.filter(
                      v => v !== '',
                    ),
                }
              : {}),
            ...(this.wechatComponentSettingForm.downloadDomain &&
            this.wechatComponentSettingForm.downloadDomain.filter(v => v !== '')
              .length > 0
              ? {
                  downloadDomain:
                    this.wechatComponentSettingForm.downloadDomain.filter(
                      v => v !== '',
                    ),
                }
              : {}),
          };
          this.submitForm('微信第三方平台设置', wechatComponentSettingForm);
        }
      } else {
        return false;
      }
    });
  }
  //刷新令牌
  private async refreshToken() {
    const config = await configs.update(
      filter =>
        filter(
          f => f('spec')('type'),
          e => e.$eq('易联云设置'),
        ),
      update => update.$unset(s => s(f => f('spec')('accessToken'), '')),
      {
        watch: {
          filter: filter =>
            filter(
              f => f('operationType'),
              e => e.$eq('update'),
            )(
              f => f('fullDocument')('spec')('accessToken'),
              e => e.$exists(true),
            ),
        },
        fullResource: true,
      },
    );
    if (config) {
      if (config[0].spec.type === '易联云设置') {
        (this.ylyConfigForm as YlyConfigSpec).accessToken =
          config[0].spec.accessToken;
      }
    }
  }
  //提交数据
  private submitForm(
    name:
      | '微信第三方平台设置'
      | '微信第三方平台令牌'
      | '应用默认配额'
      | '易联云设置',
    data:
      | WechatComponentSettingSpec
      | YlyConfigSpec
      | ApplicationDefaultQuotaSpec
      | WechatComponentTokenSpec,
  ) {
    configs
      .update(
        filter =>
          filter(
            f => f('spec')('type'),
            e => e.$eq(name),
          ),
        update => update.$set(s => s(f => f('spec'), data)),
      )
      .then(res => {
        if (res.length > 0) {
          this.$message.success('保存成功');
        }
      });
  }
  //增加文本框
  private addInput(
    type:
      | 'webviewDomain'
      | 'requestDomain'
      | 'wsRequestDomain'
      | 'uploadDomain'
      | 'downloadDomain',
  ) {
    if (this.wechatComponentSettingForm) {
      if (this.wechatComponentSettingForm[type]) {
        (this.wechatComponentSettingForm[type] as Array<string>).push('');
      } else {
        this.$set(this.wechatComponentSettingForm, type, ['']);
      }
    }
  }
  //删除文本框
  private removeInput(
    type:
      | 'webviewDomain'
      | 'requestDomain'
      | 'wsRequestDomain'
      | 'uploadDomain'
      | 'downloadDomain',
    index: number,
  ) {
    if (this.wechatComponentSettingForm) {
      (this.wechatComponentSettingForm[type] as Array<string>).splice(index, 1);
    }
  }
}
