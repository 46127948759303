
import {Component, Vue} from 'vue-property-decorator';
import {Form} from 'element-ui';
import {printTemplates} from '../../../../resources';
import {ObjectId} from 'bson';
import {AxiosError} from 'axios';
import {MonacoCodeEditor} from 'monaco-code';
import data from './data.json';

@Component({
  name: 'printTemplateEdit',
  components: {
    MonacoCodeEditor,
  },
})
export default class extends Vue {
  private valueShow = JSON.stringify(data, undefined, ' ');
  private cancelValueShow = JSON.stringify(data, undefined, ' ');
  private formData = {
    name: '', //名称
    provider: '', //提供商
    model: '', //打印机型号
    template: null as null | string, //模板
    default: false, //默认模板
    remark: '', //备注
    cancelTemplate: '请输入模板', //取消模板
  }; //提交数据
  private rules = {
    name: [{required: true, message: '请输入名称', trigger: 'blur'}],
    provider: [{required: true, message: '请输入提供商', trigger: 'blur'}],
    model: [{required: true, message: '请输入打印机型号', trigger: 'blur'}],
    template: [{required: true, message: '请输入模板', trigger: 'blur'}],
  }; //提交验证
  private submitFlag = false; //提交开关
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private editerOptions = {
    colorDecorators: true,
    accessibilitySupport: true,
  };
  private editerShowOptions = {
    colorDecorators: true,
    readOnly: true,
  };

  async created() {
    try {
      const printTemplate = (
        await printTemplates.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            ),
          ),
        )
      ).find(() => true);
      this.formData.name = printTemplate?.spec.name ?? '';
      this.formData.provider = printTemplate?.spec.provider ?? '';
      this.formData.model = printTemplate?.spec.model ?? '';
      this.formData.template = printTemplate?.spec.template ?? '';
      this.formData.cancelTemplate = printTemplate?.spec.cancelTemplate ?? '';
      this.formData.default = printTemplate?.spec.default ?? false;
      this.formData.remark = printTemplate?.spec.remark ?? '';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'list',
        id: this.$route.query.id,
      },
    });
  }
  //提交
  private submitForm() {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        this.submitFlag = true;
        try {
          printTemplates
            .update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.detailId),
                ),
              update =>
                update.$set(s =>
                  s(f => f('spec'), {
                    name: this.formData.name,
                    provider: this.formData.provider,
                    model: this.formData.model,
                    template: this.formData.template as string,
                    cancelTemplate: this.formData.cancelTemplate as string,
                    default: this.formData.default,
                    remark: this.formData.remark,
                  }),
                ),
            )
            .then(res => {
              if (res.length > 0) {
                this.$message.success('保存成功');
                this.backBtn();
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('不能重复');
            }
          }
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
}
