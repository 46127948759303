
import {Component, Vue, Watch} from 'vue-property-decorator';
import Submenu from '@/components/Submenu/index.vue';
import Character from './components/character/index.vue';
import printTemplate from './components/printTemplate/index.vue';
import WxTemplate from './components/wxThirdParty/WxTemplate/index.vue';
import TemplateDraft from './components/wxThirdParty/TemplateDraft/index.vue';
import systemConfig from './components/config/index.vue';

@Component({
  name: 'system',
  components: {
    Submenu,
    Character,
    printTemplate,
    WxTemplate,
    TemplateDraft,
    systemConfig,
  },
})
export default class extends Vue {
  private menu = '';
  private submenu = [
    {
      top: '',
      list: [{name: '角色管理', active: true}],
    },
    {
      top: '微信第三方',
      list: [
        {name: '模板管理', active: false},
        {name: '草稿管理', active: false},
      ],
    },
    {
      top: '',
      list: [{name: '打印模板', active: false}],
    },
    {
      top: '',
      list: [{name: '系统配置', active: false}],
    },
  ];
  created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    this.menu = this.$route.query.menu
      ? (this.$route.query.menu as string)
      : '角色管理';
  }
  @Watch('$route', {deep: true})
  routechange() {
    this.$store.state.applicationId = 'admin';
  }
}
