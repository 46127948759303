
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {wechatTemplates} from '../../../../../resources';
import moment from 'moment';
import {WechatTemplateDraft} from '@/externals/MaxCI-WechatTemplateDraft-v1';

@Component({
  name: 'schoolList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private dialogDel = false;
  private operateId = 1; //操作数据的id
  private formData = {
    channelType: '微信小程序',
  };
  private dialogTemplate = false; //授权弹窗
  private operateData: WechatTemplateDraft | null = null;
  private total = 0;
  private list: Array<WechatTemplateDraft> = [];
  private listLoading = true;
  private submitFlag = false; //提交开关

  async created() {
    this.updateList();
  }
  //删除提示
  private delPopUps(id: number) {
    this.operateId = id;
    this.dialogDel = true;
  }
  //删除
  private async delSumbit() {
    try {
      const wechatTemplate = await wechatTemplates.delete(filter =>
        filter(
          f => f('spec')('id'),
          e => e.$eq(this.operateId),
        ),
      );
      if (wechatTemplate) {
        this.dialogDel = false;
        this.operateId = 1;
        this.$message.success('已删除');
        this.updateList();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //获取表格
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await wechatTemplates.find(stage => stage);
      list.sort((a: WechatTemplateDraft, b: WechatTemplateDraft) => {
        return b.spec.id - a.spec.id;
      });
      this.list = list;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
}
