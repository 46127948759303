
import {Component, Vue, Prop} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {permissions} from '../../../../resources';
import {Permission} from '@/externals/Core-Permission-v1';
import {ObjectId} from 'bson';

const securitys = {
  high: '强',
  medium: '中',
  low: '低',
};

@Component({
  name: 'authority',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop()
  private roleId: ObjectId | null;
  private total = 0;
  private list: Array<Permission> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private dialogDel = false;
  private operateId = '';

  async created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    this.updateList();
  }
  convertSecurity(security: 'high' | 'medium' | 'low') {
    return securitys[security];
  }
  private async updateList() {
    this.listLoading = true;
    try {
      if (this.roleId) {
        const list = await permissions.find(stage =>
          stage.$match(match =>
            match(
              f => f('spec')('roleId'),
              e => e.$eq(this.roleId as ObjectId),
            ),
          ),
        );
        this.list = list;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
}
