
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {roles} from '../../../../resources';
import Authority from '../authority/index.vue';
import {ObjectId} from 'bson';
import {Role} from '@/externals/Core-Role-v1';

@Component({
  name: 'character',
  components: {
    Pagination,
    Authority,
  },
})
export default class extends Vue {
  private authorityShow = false;
  private roleId: ObjectId | null = null;
  private total = 0;
  private list: Array<Role> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };

  async created() {
    this.updateList();
  }
  private detailAuthority(id: ObjectId) {
    this.authorityShow = true;
    this.roleId = id;
  }
  private back() {
    this.authorityShow = false;
  }
  private async updateList() {
    this.listLoading = true;

    try {
      const list = await roles.find(stage =>
        stage.$facet(facet =>
          facet('table', tableStage =>
            tableStage
              .$skip((this.listParams.page - 1) * this.listParams.limit)
              .$limit(this.listParams.limit),
          )('count', countStage => countStage.$count('count')),
        ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
}
