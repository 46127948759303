
import {Component, Vue} from 'vue-property-decorator';
import {Form} from 'element-ui';
import {printTemplates} from '../../../../resources';
import {AxiosError} from 'axios';
import {MonacoCodeEditor} from 'monaco-code';
import data from './data.json';

@Component({
  name: 'printTemplateAdd',
  components: {
    MonacoCodeEditor,
  },
})
export default class extends Vue {
  private valueShow = JSON.stringify(data, undefined, ' ');
  private cancelValueShow = JSON.stringify(data, undefined, ' ');
  private formData = {
    name: '', //名称
    provider: '', //提供商
    model: '', //打印机型号
    template: '请输入模板', //模板
    default: false, //默认模板
    remark: '', //备注
    cancelTemplate: '请输入模板', //取消模板
  }; //提交数据
  private rules = {
    name: [{required: true, message: '请输入名称', trigger: 'blur'}],
    provider: [{required: true, message: '请输入提供商', trigger: 'blur'}],
    model: [{required: true, message: '请输入打印机型号', trigger: 'blur'}],
    template: [{required: true, message: '请输入模板', trigger: 'blur'}],
  }; //提交验证
  private submitFlag = false; //提交开关

  private editerOptions = {
    colorDecorators: true,
  };
  private editerShowOptions = {
    colorDecorators: true,
    readOnly: true,
  };

  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'list',
        id: this.$route.query.id,
      },
    });
  }
  //提交
  private submitForm() {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        this.submitFlag = true;
        try {
          printTemplates
            .create([
              {
                spec: {
                  name: this.formData.name,
                  provider: this.formData.provider,
                  model: this.formData.model,
                  template: this.formData.template,
                  cancelTemplate: this.formData.cancelTemplate,
                  default: this.formData.default,
                  ...(this.formData.remark
                    ? {remark: this.formData.remark}
                    : {}),
                },
              },
            ])
            .then(res => {
              if (res.length > 0) {
                this.$message.success('保存成功');
                this.backBtn();
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('不能重复');
            }
          }
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
}
